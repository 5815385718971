import HttpClient from '@/api/http-client';

const END_POINT = '/api/v1/upload';

const getVaccinationEndpoint = () => {
  const path = `${HttpClient.getBaseURL()}${END_POINT}/vaccination`;
  return path;
};

const getVaccinationHepEndpoint = () => {
  const path = `${HttpClient.getBaseURL()}${END_POINT}/vaccination/hep`;
  return path;
};

const getCovidTestEndpoint = () => {
  const path = `${HttpClient.getBaseURL()}${END_POINT}/covid-test`;
  return path;
};

const getOtherTestEndpoint = () => {
  const path = `${HttpClient.getBaseURL()}${END_POINT}/other-test`;
  return path;
};

export default {
  getVaccinationEndpoint,
  getCovidTestEndpoint,
  getOtherTestEndpoint,
  getVaccinationHepEndpoint,
};
