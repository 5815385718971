import HttpClient from '@/api/http-client';

function getEndpoint() {
  return '/api/v1/employees';
}

const getAll = () => HttpClient.get(getEndpoint());

const getVaccinationList = (employeeId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/vaccination`;
  return HttpClient.get(path);
};

const getVaccinationSummary = (employeeId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/vaccination/summary`;
  return HttpClient.get(path);
};

const submitVaccination = (employeeId, vaccination) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/vaccination`;
  return HttpClient.post(path, vaccination);
};

const deleteVaccination = (employeeId, vaccinationId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/vaccination/${vaccinationId}`;
  return HttpClient.delete(path);
};

const getCovidTestList = (employeeId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/covid-test`;
  return HttpClient.get(path);
};

const submitCovidTest = (employeeId, covidTest) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/covid-test`;
  return HttpClient.post(path, covidTest);
};

const deleteCovidTest = (employeeId, covidTestId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/covid-test/${covidTestId}`;
  return HttpClient.delete(path);
};

const getOtherTestList = (employeeId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/other-test`;
  return HttpClient.get(path);
};

const submitOtherTest = (employeeId, otherTest) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/other-test`;
  return HttpClient.post(path, otherTest);
};

const deleteOtherTest = (employeeId, otherTestId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/other-test/${otherTestId}`;
  return HttpClient.delete(path);
};

const getRestaurantList = (employeeId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/restaurant`;
  return HttpClient.get(path);
};

const getVaccinationHepList = (employeeId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/vaccination/hep`;
  return HttpClient.get(path);
};

const submitVaccinationHep = (employeeId, vaccination) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/vaccination/hep`;
  return HttpClient.post(path, vaccination);
};

const deleteVaccinationHep = (employeeId, vaccinationId) => {
  const endpoint = getEndpoint();
  const path = `${endpoint}/${encodeURIComponent(employeeId)}/vaccination/hep/${vaccinationId}`;
  return HttpClient.delete(path);
};

export default {
  getAll,
  getVaccinationList,
  getVaccinationSummary,
  submitVaccination,
  deleteVaccination,
  getCovidTestList,
  submitCovidTest,
  deleteCovidTest,
  getOtherTestList,
  submitOtherTest,
  deleteOtherTest,
  getRestaurantList,
  getVaccinationHepList,
  submitVaccinationHep,
  deleteVaccinationHep,
};
